<template>
    <transition name="slide-up">
        <div v-show="show" class="sheet">
            <div class="title spoqa-f-medium f-24 c-black m-b-20" v-html="$translate('STAR_RATING')" />
            <div class="content" v-html="$translate('STAR_RATING_DESC')" />
            <div class="c-primary f-13 m-b-12 m-t-12" v-html="$translate('START_RATING_SECRET')" />
            <div class="star m-b-40 m-t-28">
                <StarRating :icon="'star'" @onRating="onRating" />
            </div>
        </div>
    </transition>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ModalStarRating',
    data: () => ({
        show: false,
    }),
    props: ['options'],
    mounted() {
        setTimeout(() => {
            this.show = true
        })

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'ProfilePage_BottomSheet_Rating',
                option: {
                    target_user_id: this.options.targetId,
                    profile_status: this.$store.getters.profileStatus,
                    target_profile_status: this.$targetUserProfileStatus(this.options.user),
                },
            },
        })
    },
    methods: {
        async onRating(rating) {
            try {
                await userService.starRate({ target_id: this.options.targetId, rate: rating })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Rate',
                        option: {
                            target_user_id: this.options.targetId,
                            channel: 'ModalStarRating',
                            rating: rating,
                            profile_status: this.$store.getters.profileStatus,
                            target_profile_status: this.$targetUserProfileStatus(this.options.user),
                        },
                    },
                })

                setTimeout(() => {
                    this.$toast.success('매력 평가 완료!')
                })
                this.$store.dispatch('loadBadges')
                this.$emit('close')
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.sheet {
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
}
.content {
    color: $grey-08;
    font-size: 13px;
    line-height: 18px;
    @include spoqa-f-regular;
}
</style>
